<template>
  <div id="calificacionIncidencias" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-2 mb-n5"
        >INCIDENCIAS POR CALIFICAR</v-row
      >
      <v-row no-gutters justify="start" class="mr-12 mb-4 mt-8">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerEmpresa()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="incidences"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Incidencias por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">
                    {{
                      item.empleado.nombre +
                      " " +
                      item.empleado.apellidoPaterno +
                      " " +
                      item.empleado.apellidoMaterno
                    }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.fechaAusentismoInicio.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.fechaReincorporacion.substr(0, 10) }}
                  </td>
                  <td class="text-sm-center">
                    {{ item.viaComunicacion.viaComunicacion }}
                  </td>
                  <td class="text-sm-center">{{ item.comentario }}</td>
                  <td class="text-sm-center">
                    {{ item.causaAusentismo.causa }}
                  </td>
                  <td class="text-sm-center">{{ item.estatus.estatus }}</td>
                  <td
                    class="text-sm-center"
                    v-if="item.estatus.estatus != 'Por calificar'"
                  >
                    {{ item.calificacion.aplicacion }}
                  </td>
                  <td
                    class="text-sm-center"
                    v-if="item.estatus.estatus === 'Por calificar'"
                  >
                    <span class="pendiente fa-2x ml-n3" @click="aplicar(item)">
                      <font-awesome-icon icon="clock" />
                    </span>
                  </td>
                  <td class="text-sm-center" v-if="item.documentos.length < 3">
                    <v-col
                      v-if="item.documentos.length == 1"
                      cols="12"
                      sm="12"
                      md="12"
                      lg="12"
                    >
                      <v-img
                        max-height="125"
                        max-width="125"
                        :src="
                          item.documentos[0].empleadoDocumentos.urlDocumento
                        "
                        @click="
                          abrirDocumento(
                            item.documentos[0].empleadoDocumentos.urlDocumento
                          )
                        "
                      ></v-img>
                    </v-col>
                    <v-row v-else>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        v-for="foto in item.documentos"
                        :key="foto.empleadoDocumentosId"
                      >
                        <v-img
                          max-height="125"
                          max-width="125"
                          :src="foto.empleadoDocumentos.urlDocumento"
                          @click="
                            abrirDocumento(foto.empleadoDocumentos.urlDocumento)
                          "
                        ></v-img> </v-col
                    ></v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              aplica = 0;
              comentario = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="guardar()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Incidencia</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DOCUMENTO-->
    <v-dialog v-model="documento" width="400" @click="active = true">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Documento presentado</span>
        </v-card-title>
        <v-card-text class="mt-4">
          <v-img :src="url"></v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text :href="url" download>Descargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Editar item-->
    <v-dialog v-model="edit" width="350">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Pendiente por Calificar</span>
        </v-card-title>
        <v-card-text>
          <!--EMPRESA-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="Aplica">Aplica</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <select
                name="Aplica"
                id="Aplica"
                v-model="aplica"
                class="mt-n6"
                style="width: 170px !important"
              >
                <option selected disabled value="0">Seleccione</option>
                <option
                  v-for="aplicacion in aplicaciones"
                  :key="aplicacion.id"
                  v-bind:value="aplicacion.id"
                >
                  {{ aplicacion.aplicacion }}
                </option>
              </select>
            </v-col>
          </v-row>
          <!--NOMBRE DE AREA-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="comentario">Comentario</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <textarea
                type="text"
                name="comentario"
                id="comentario"
                placeholder="Obligatorio"
                class="inputs"
                style="width: 170px !important"
                autocomplete="false"
                required
                maxlength="180"
                v-model="comentario"
                @keypress="alfaNumerico($event)"
              />
            </v-col>
          </v-row>
          <!--NOMBRE CORTO-->
          <v-row>
            <v-col cols="12" sm="4" md="4" lg="4">
              <label for="autorizo">Califico Autorización</label></v-col
            >
            <v-col cols="12" sm="8" md="8" lg="8">
              <input
                type="text"
                name="autorizo"
                id="autorizo"
                placeholder="Obligatorio"
                class="inputs"
                autocomplete="false"
                disabled
                maxlength="30"
                @keypress="alfaNumerico($event)"
                v-model="person"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              edit = false;
              aplica = 0;
              comentario = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="aviso()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      active: false,
      expiration: false,
      search: "",
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: "Nombre Empleado",
          align: "center",
          value: "empleado.nombre",
        },
        {
          text: "Fecha Ausentismo",
          value: "fechaAusentismoInicio",
          align: "center",
        },
        {
          text: "Fecha Reincorporación",
          value: "fechaReincorporacion",
          align: "center",
        },
        {
          text: "Vía de Comunicación",
          value: "viaComunicacion.viaComunicacion",
          align: "center",
        },
        {
          text: "Comentario",
          value: "comentario",
          align: "center",
          sortable: false,
        },
        {
          text: "Causa",
          value: "causaAusentismo.causa",
          align: "center",
        },
        {
          text: "Estatus",
          value: "estatus.estatus",
          align: "center",
        },
        {
          text: "Calificación",
          align: "center",
          value:"calificacion.aplicacion"
        },
        {
          text: "Documentos",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      incidences: [],
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      edit: false,
      vacio: false,
      comentario: "",
      nombreCorto: "",
      nombreArea: "",
      empresa: 0,
      nivel: 0,
      url: "",
      documento: false,
      persona: localStorage.empleadoIdGlobal,
      person: localStorage.nombreEmpleadoGlobal,
      aplicaciones: [],
      rolePersona: localStorage.roles,
      aplica: 0,
    };
  },
  methods: {
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras,espacio y números
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aplicar(item) {
      this.id = item.id;
      this.edit = true;
    },
    aviso() {
      this.edit = false;
      this.respuesta = "¿Esta seguro de guardar su calificación?";
      this.advice = true;
    },
    guardar() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/ausentismosLaborales/calificar-ausencia/" + this.id,
          {
            AplicacionId: parseInt(this.aplica),
            Comentario: this.comentario,
            EmpleadoCalificaId: parseInt(this.persona),
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.aplica = 0;
          this.comentario = "";
          this.respuesta = "La calificación fue otorgada con éxito.";
          this.listar();
          this.obtenerEmpresa();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.aplica = 0;
            this.comentario = "";
            this.respuesta =
              "La calificación no fue otorgada, vuelva a intentarlo.";
            this.listar();
            this.obtenerEmpresa();
            this.confirmation = true;
          }
        });
    },
    onClickOutside() {
      this.active = false;
    },
    abrirDocumento(url) {
      this.url = url;
      this.documento = true;
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
      this.ausentismoAplicaciones();
    },
    ausentismoAplicaciones() {
      this.show = true;
      axios
        .get(Server + "/ausentismosLaborales/catalogo-aplicaciones", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.aplicaciones = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    obtenerEmpresa() {
      this.incidences = [];
      this.show = true;
      var urlCompleta = "/ausentismosLaborales/por-empresa/" + this.enterprise;
      urlCompleta += "?empleadoId=" + this.persona;
      urlCompleta += "&role=" + this.rolePersona;
      axios
        .get(Server + urlCompleta, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((incidencia, index) => {
            if (incidencia.calificacionId == null) {
              //console.log("no tiene calificacion");
              this.incidences.push(incidencia);
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerEmpresa();
  },
};
</script>